<template>
  <div v-if="allowAiChat">
    <slot name="button" :openPopover="openPopover">
      <b-btn :variant="btnVariant"
            v-p-tooltip="{ value: `Muse AI ${museSubTitle}`, disabled: !tooltip }"
            class="d-flex-center"
            @click="openPopover($event)">
        <fluency-icon :type="btnIcon" size="md" /> <span v-if="btnText" class="button-text ml-2">{{btnText}}</span>
      </b-btn>
    </slot>

    <p-popover ref="musePopover"
               class="muse-popover">
      <!-- TITLE -->
      <div class="d-flex-center pb-4 border-bottom mb-4" style="min-width: 400px; max-width: 500px">
        <div class="flex-grow-1 mb-0">
          <h4 class="d-flex-center m-0">
            <fluency-icon type="muse"/>
            <span class="muse-text-gradient right-border pl-3">Muse AI</span>{{museSubTitle}}
          </h4>
        </div>
        <div class="text-secondary cursor-pointer"
              @click="closePopover()">
          <fluency-icon type="x" />
        </div>
      </div>

      <!-- BODY -->
      <div style="min-width: 400px; max-width: 500px">

        <div v-html="description" class="pb-3"></div>
      
        <div v-if="!submittedPrompt" class="d-flex-center justify-content-between rounded pt-3">
          <p-textarea v-model="inputPrompt"
                      class="w-100"
                      auto-resize
                      rows="1"
                      :placeholder="placeholder"
                      ref="textareaRef"
                      @keydown.enter="fetch()"/>
          <b-button variant="link-primary" class="d-flex-center-center ml-3" @click="fetch()" :disabled="!inputPrompt">
            <fluency-icon type="arrowUp"></fluency-icon>
          </b-button>
        </div>
        <div v-else>
          <div class="mt-4">
            <strong class="mt-4" style="border-radius: 20px;">"{{ submittedPrompt }}"</strong>
          </div>
          <div v-if="loading" class="pt-2 text-center">
            <fluency-loader dots color/>
            <p>{{ status }}</p>
          </div>
          <template v-else-if="submittedPrompt && response.modelResponse">
            <t-card class="mt-3">
              <div class="d-flex">
                <fluency-icon :type="btnIcon" size="md" class="mr-3"/>
                <div>
                  <template v-for="(chunk, index) in chunks" :key="index">
                    <template v-if="chunk.type === 'text'">
                      <span v-markdown="chunk.content"></span>
                    </template>
                    <template v-if="chunk.type === 'component'">
                      <div class="d-flex-center justify-content-between copy-from-response bg-light py-3 px-4 rounded">
                        <code>{{ sanitizeContent(chunk.content) }}</code>
                        
                        <div class="d-flex">
                          <b-button variant="link-dark"
                                    class="p-0"
                                    v-p-tooltip="`Apply to ${museSubTitle}`"
                                    @click="$emit('apply', sanitizeContent(chunk.content)); closePopover()">
                            <fluency-icon type="apply"/>
                        </b-button>
                        <copy-to-clipboard  variant="link-dark" :val="sanitizeContent(chunk.content)" class="p-0" v-p-tooltip="'Copy to Clipboard'"/>
                        </div>
                      </div>
                    </template>
                  </template>
                </div>
              </div>
            </t-card>
            <div class="text-right pt-4">
              <t-button-inline @click="reset">Ask Another Question</t-button-inline>
            </div>
          </template>
        </div>
      </div>
      
    </p-popover>

    <async-fetcher hide-loader
                   :timeout="300"
                   ref="asyncFetcher"
                   @start="loading = true"
                   @end="loading = false"
                   @kill="loading = false"
                   @status="handleStatus($event)"
                   @success="handleSuccess($event)" />
  </div>
</template>

<script>
import AsyncFetcher from 'core-ui/components/common/asyncFetcher'
import FluencyLoader from 'core-ui/components/common/fluencyLoader'
import CopyToClipboard from './copyToClipboard'

export default {
  name: 'musePopover',
  components: { AsyncFetcher, FluencyLoader, CopyToClipboard },
  props: {
    btnVariant: {
      type: String,
      default: 'link-muse-purple'
    },
    btnIcon: {
      type: String,
      default: 'muse'
    },
    btnText: {
      type: String,
      default: ''
    },
    museSubTitle: {
      type: String
    },
    context: {
      type: String
    },
    placeholder: {
      type: String
    },
    description: {
      type: String
    },
    prePrompt: {
      type: String
    }
  },
  data () {
    return {
      inputPrompt: '',
      submittedPrompt: '',
      status: '',
      response: {},
      loading: false
    }
  },
  computed: {
    lastStatus () {
      return this.response.content[this.response.content.length - 1]
    },
    allowAiChat () {
      return this.$store.getters.user?.capabilities?.AIChat
    },
    chunks () {
      if (this.response?.modelResponse) {
        return this.processMarkdown(this.response.modelResponse)
      }
      return []
    }
  },
  methods: {
    openPopover ($event) {
      this.$refs.musePopover.show($event)
      this.focusInput()
    },

    closePopover () {
      this.$refs.musePopover.hide()
    },

    focusInput () {
      this.$nextTick(() => {
        this.$refs.textareaRef?.$el?.focus()
      })
    },

    handleStatus (status) {
      this.status = status.content[0]
    },

    handleSuccess (success) {
      this.response = success 
      this.alignOverlay()
    },

    reset () {
      this.inputPrompt = this.defaultPrompt
      this.submittedPrompt = ''
      this.status = ''
      this.alignOverlay()
      this.focusInput()
    },

    alignOverlay () {
      setTimeout(() => { this.$refs.musePopover.alignOverlay() }) 
    },

    processMarkdown (response) {
      const chunks = []
      const regex = /```([\s\S]*?)```/g // anything between 3 backticks
      let lastIndex = 0
      let match

      while ((match = regex.exec(response)) !== null) {
        // Push the text before the match
        if (match.index > lastIndex) {
          chunks.push({ type: 'text', content: response.slice(lastIndex, match.index) })
        }

        // Push the copy paste component flag
        chunks.push({ type: 'component', content: match[1] })

        lastIndex = regex.lastIndex
      }

      // Push any remaining text after the last match
      if (lastIndex < response.length) {
        chunks.push({ type: 'text', content: response.slice(lastIndex) })
      }

      return chunks
    },

    sanitizeContent (content) {
      let sanitized = content
      sanitized.trim()
      sanitized = sanitized.replace('plaintext', '')
        .replace(/['"]/g, '')
        
      return sanitized
    },

    async fetch () {
      if (!this.inputPrompt) return 
      this.submittedPrompt = this.inputPrompt

      const prompt = {
        history: [],
        current: {
          userPrompt: this.prePrompt ? `${this.prePrompt} ${this.submittedPrompt}` : this.submittedPrompt,
          usageContext: this.context
        }
      }

      this.$refs.asyncFetcher.asyncFetch(this.$res.ai.chatPrompt, [prompt])
    }
  }
}
</script>

<style lang="scss">
.muse-popover {
  margin-top: 10px!important;
  .btn-link-primary.disabled,
  .btn-link-primary.disabled:hover {
    background: transparent;
    border-color: transparent;
    color: $text-muted;
  }
  pre code {
    white-space: normal;
  }
  .copy-from-response {
    margin-bottom: 1rem;
    pre {
      margin: 0;
    }
  }
}
</style>
