<template>
  <span class="d-flex flex-wrap" :class="{ 'flex-column': shouldBadgify }">
    <span v-for="(row, rowKey) in rowsAndParts"
          :key="rowKey"
          class="d-block mb-1">
      <span v-for="(part, partKey) in row"
            :key="`${rowKey}-${partKey}`"
            class=""
            :class="[
              !shouldBadgify ? classes.nonBadgified : '',
              shouldBadgify ? classes.badgified : ''
            ]">
        <span :class="[
             shouldBadgify && isOnlyTag(part) ? classes.badgifiedTagOnly : '',
             shouldBadgify && isOnlyText(row) ? classes.badgifiedTextOnly : '',
             shouldBadgify && isOnlyText(row) && subtle ? 'text-black-60' : '',
             shouldBadgify && !isOnlyText(row) && !isOnlyTag(part) ? classes.badgifiedMixed : ''
           ]">
          <b-badge v-if="hasTag(part)"
                   :variant="isAlias(part) ? 'inverted-info' : 'info'"
                   class="madlib-badge badge-full mb-1 text-wrap text-left text-break"
                   :class="[shouldBadgify ? 'line-height-lg' : '']">
            <template v-if="isAlias(part)">@</template>{{stripOuterTagSyntax(part)}}

            <muse-popover btn-text="Need Assistance?"
                          :muse-sub-title="`Tag`"
                          placeholder="Create a tag function to..."
                          :description="`Ask Muse for assistance for the tag, <strong>${stripOuterTagSyntax(part)}</strong>.`"
                          :pre-prompt="`For the tag ${part}, `"
                          context="tag"
                          class="d-inline"
                          @apply="applySuggestion(part, $event)">
                <template #button="{ openPopover }">
                  <span v-p-tooltip.top="'Tag Assistance'" @click.stop.prevent="openPopover($event)">
                    <fluency-icon type="muse" class="stroke-white p-1 ml-2"/>
                  </span>
                </template>
            </muse-popover>

            <span v-if="(canCreateAliases && isComplex(part))" @click.stop="$refs[`tagActions-${rowKey}-${partKey}`]?.[0]?.show($event)" style="margin-left: -5px;">
              <fluency-icon type="ellipses" class="p-1"  v-p-tooltip.top="'More Actions'"></fluency-icon>
            </span>
            <p-popover :ref="`tagActions-${rowKey}-${partKey}`" :pt:content="`d-flex flex-column`">
              <b-button v-if="canCreateAliases && isComplex(part)"
                        variant="link-dark"
                        class="px-0 py-1 d-flex-center"
                        @click.stop.prevent="closeTagActionsPopover(rowKey, partKey); createDynamicTag(part)">
                <div style="min-width: 20px" class="pr-2"><fluency-icon type="lightningBolt" class="text-warning" style="top: 2px"/></div>
                 Convert To Alias
              </b-button>
              <!-- <b-button v-if="canMarkAsRequired"
                        variant="link-dark"
                        class="px-0 py-1 d-flex-center"
                        @click.stop.prevent="toggleRequiredTag(part)">
                <div style="min-width: 25px" class="pr-2">
                  <fluency-icon :type="tagIsRequired(part) ? 'star' : 'emptyStar'"
                                :class="tagIsRequired(part) ? 'stroke-warning' : ''" />
                </div>
                {{ tagIsRequired(part) ? 'Marked as Required' : 'Mark as Required' }}
              </b-button> -->
            </p-popover>
          </b-badge>
          <span v-else>{{part}}</span>
        </span>
      </span>
    </span>
  </span>
</template>

<script>
import MusePopover from '../../../apps/backpack/src/components/common/musePopover.vue'

export default {
  name: 'madLibTaggify',
  components: { MusePopover },
  props: {
    inputString: {
      type: [String, Number],
      default: ''
    },
    badgify: {
      type: Boolean,
      default: true
    },
    canCreateAliases: {
      type: Boolean,
      default: false
    },
    canMarkAsRequired: {
      type: Boolean,
      default: false
    },
    subtle: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      classes: {
        nonBadgified: 'mr-1',
        badgified: 'mb-2',
        badgifiedTagOnly: '',
        badgifiedTextOnly: 'badge badge-light badge-full border font-weight-normal text-wrap text-left line-height-lg',
        badgifiedMixed: 'text-wrap text-left line-height-lg'
      }
    }
  },
  computed: {
    rowsAndParts () {
      return this.solveRows(this.inputString)
    },
    shouldBadgify () {
      return this.badgify && typeof this.inputString === 'string' && this.inputString.indexOf('\n') >= 0
    },
    dynamicTagAliases () {
      return this.$store.getters.blueprintEditorCurrentDynamicTagAliases
    },
    requiredTags () {
      return this.$store.getters.blueprintEditorCurrentRequiredTags || []
    }
  },
  methods: {
    solveRows (str) {
      const result = []

      if (typeof str !== 'string') {
        result.push(this.solveParts(str))
        return result
      }

      const toLines = str.split('\n')

      toLines.forEach(line => {
        result.push(this.solveParts(line))
      })

      return result
    },
    solveParts (str) {
      const result = []
      const open = '['
      const close = ']'
      let stack = 0

      if (typeof str !== 'string') {
        result.push(str)
        return result
      }

      const splitArr = str.split('')
      let currentString = ''

      splitArr.forEach((char, ind) => {
        if (char === open) {
          if (stack === 0) {
            result.push(currentString)
            currentString = ''
          }
          stack++
          currentString += char
        } else if (char === close) {
          currentString += char
          if (stack === 1) {
            result.push(currentString)
            currentString = ''
          }
          stack--
        } else if (this.badgify && char === '\n') {
          result.push(currentString)
          currentString = ''
        } else {
          currentString += char
        }
      })

      result.push(currentString)

      return result.filter(phrase => { return phrase !== '' })
    },
    hasTag (input) {
      return typeof input === 'string' && input.indexOf('[') >= 0
    },
    isAlias (val) {
      if (this.isOnlyTag(val)) {
        const strippedVal = this.stripOuterTagSyntax(val)
        return this.dynamicTagAliases.includes(strippedVal)
      }

      return false
    },
    isComplex (val) {
      if (!this.canCreateAliases) {
        return false
      }

      if (val.indexOf('>>') >= 0) {
        return true
      }
      if (val.indexOf('||') >= 0) {
        return true
      }
      if (val.indexOf('::') >= 0) {
        return true
      }

      return false
    },
    isOnlyTag (val) {
      return val.startsWith('[') && val.endsWith(']')
    },
    isOnlyText (row) {
      return Array.isArray(row) && row.length === 1 && row[0].indexOf('[') === -1 && row[0].indexOf(']') === -1
    },
    stripOuterTagSyntax (str) {
      return str.slice(1, -1)
    },
    // if tag is formatted [test>>UPPERCASE], only return 'test'
    getParentTag (str) {
      return str.slice(1, -1).match(/^[^\W_]+/)?.[0] || ''
    },
    createDynamicTag (tag) {
      this.$eventBus.$emit('create-dynamic-tag-alias', tag)
    },
    closeTagActionsPopover (rowKey, partKey) {
      this.$refs[`tagActions-${rowKey}-${partKey}`]?.[0]?.hide()
    },
    openMusePopover ($event, rowKey, partKey) {
      this.$refs[`musePopover-${rowKey}-${partKey}`]?.[0]?.openPopover($event)
    },
    applySuggestion (originalTag, newTag) {
      const stripped = newTag.replace(/[\n\r\t]/g, '')
      const replaced = this.inputString.replace(originalTag, stripped)
      this.$emit('applyTagSuggestion', replaced)
    },
    toggleRequiredTag (tag) {
      let requiredTags = this.requiredTags

      const incoming = this.getParentTag(tag)

      if (!incoming) {
        this.$toast(`There was an issue marking "${incoming}" as required.`, 'error')
        return
      }

      if (this.tagIsRequired(tag)) {
        requiredTags = requiredTags.filter(t => t !== incoming)
      } else {
        requiredTags.push(incoming)
      }
      this.$eventBus.$emit('set-required-tags', requiredTags)
    },
    tagIsRequired (tag) {
      const incoming = this.getParentTag(tag)
      
      return this.requiredTags?.includes(incoming)
    }
  }
}
</script>

<style scoped>

</style>
